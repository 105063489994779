import React, {
  memo,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import './AppHeader.less';

import { MenuOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Badge, Dropdown, Layout, Menu, Tooltip, Typography } from 'antd';
import cx from 'classnames';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { ReactComponent as AllMarketsFlagIcon } from '../../../../../assets/svgs/all-markets-flag-icon.svg';
import { ReactComponent as AmericaFlagIcon } from '../../../../../assets/svgs/america-flag-icon.svg';
import { ReactComponent as CanadaFlagIcon } from '../../../../../assets/svgs/canada-flag-icon.svg';
import { ReactComponent as MasterListIcon } from '../../../../../assets/svgs/master-list-icon.svg';
import { ReactComponent as ProductListIcon } from '../../../../../assets/svgs/product-list-icon.svg';
import ReadySetIcon from '../../../../../assets/svgs/readyset-icon.svg';
import { ListProductLibraryParams } from '../../../../../hooks/product-library';
import useDebounce from '../../../../../hooks/useDebounce';
import { useAppSelector } from '../../../../../store';
import { fetchCustomField } from '../../../../../store/features/customFields/customFieldsSlice';
import { fetchMasterLibrarianListCount } from '../../../../../store/features/masterLibrarianList/masterLibrarianListSlice';
import { filterProducts } from '../../../../../store/features/productLibrary/productLibrarySlice';
import { searchProductsByName } from '../../../../../store/features/productList/productListSlice';
import { useAppDispatch } from '../../../../../store/index';
import {
  isTabletScreenSizeOrSmaller,
  isUserAuthorized,
  propsAreEqual,
  UserRoles,
} from '../../../../../util';
import Button from '../../../../elements/Button';
import { productLibraryListViewTypeKey } from '../../../ProductLibraryView/ProductLibraryView';
import { ProductLibraryListViewType } from '../../../ProductLibraryView/components/ProductLibraryItemsList/ProductLibraryItemsList';
import { AppLayoutContext } from '../../AppRoot';
import { RoutePath } from '../../types';

const { Header } = Layout;
const { Title } = Typography;
interface AppHeaderProps extends RouteComponentProps {
  onToggleSidebar: () => void;
}

const AppHeader = ({ location, onToggleSidebar }: AppHeaderProps) => {
  const [selectedMarket, setSelectedMarket] = useState(66);
  const [viewType] = useState<ProductLibraryListViewType>(
    (localStorage.getItem(
      productLibraryListViewTypeKey
    ) as ProductLibraryListViewType) || ProductLibraryListViewType.Grid
  );

  const dispatch = useAppDispatch();
  const { searchQuery } = useAppSelector((state) => state.productLibrary);
  const { customField: markets } = useAppSelector(
    (state) => state.customFields
  );
  const { count: productListCount } = useAppSelector(
    (state) => state.productList
  );

  const { totalCount: masterLibrarianListCount } = useAppSelector(
    (state) => state.masterLibrarianList
  );

  const { sidebarCollapsed } = useContext(AppLayoutContext);
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const isTablet = useMemo(() => isTabletScreenSizeOrSmaller(), []);

  const hasPermission = useMemo((): boolean => {
    return isUserAuthorized([
      UserRoles.admin,
      UserRoles.employeeDeveloper,
      UserRoles.employeeModeler,
      UserRoles.employeeClientServices,
      UserRoles.employeeLibrarian,
      UserRoles.client,
    ]);
  }, []);

  const getHeaderTitle = useCallback((): string => {
    const pathname = location.pathname;
    const accountSettingsPathPrefix = '/account-settings/';

    switch (true) {
      case pathname.includes(RoutePath.Dashboard):
        return 'Home';
      case pathname.includes(RoutePath.OVR):
        return 'Online Virtual Research';
      case pathname.includes(RoutePath.StoreBuilder):
        return 'Front End Builder';
      case pathname.startsWith(accountSettingsPathPrefix):
        return 'Account Settings';
      case pathname.includes(RoutePath.Users):
        return 'Users';
      case pathname.includes(RoutePath.User):
        return 'User Information';
      case pathname.includes(RoutePath.Clients):
        return 'Clients';
      case pathname.includes(RoutePath.Client):
        return 'Client';
      case pathname.includes(RoutePath.CustomFields):
        return 'Custom Fields';
      case pathname.includes(RoutePath.Manual):
        return 'Manual / Videos';
      case pathname.includes(RoutePath.SystemConfigurations):
        return 'System Configurations';
      case pathname.includes(RoutePath.OvrFileManagement):
        return 'OVR File Management';
      case pathname.includes(RoutePath.ProductLibrary):
        return 'Product Library';
      case pathname.includes(RoutePath.ProductList):
        return 'Product List';
      case pathname.includes(RoutePath.MasterLibrarianList):
        return 'Master Librarian List';
      case pathname.includes(RoutePath.UploadModels):
        return 'Upload product model(s)';
      case pathname.includes(RoutePath.POGChecker):
        return 'POG Checker';
      case pathname.includes(RoutePath.MetaData):
        return 'Product Library Meta Data';
      case pathname.includes(RoutePath.ReplacementData):
        return 'Replacement Data';
      case pathname.includes(RoutePath.Roles):
        return 'Roles';
      case pathname.includes(RoutePath.Status):
        return 'Product Library Status';
      default:
        return '';
    }
  }, [location.pathname]);

  const siteHeaderClass = cx('site-header', { collapsed: sidebarCollapsed });

  const handleMarketDropdownMenuClick: MenuProps['onClick'] = useCallback(
    (e: { key: string }) => {
      const marketId = parseInt(e?.key, 10);
      setSelectedMarket(marketId);
      const params: ListProductLibraryParams = {
        _limit: viewType === ProductLibraryListViewType.Grid ? 30 : 10,
        _offset: 0,
        _order_by: 'updated_at:desc',
        ['market[]']: marketId,
      };

      dispatch(
        filterProducts({
          params,
          query: debouncedSearchQuery,
        })
      );
    },
    [viewType, debouncedSearchQuery, dispatch]
  );

  const marketIcons: { [key: number]: ReactElement } = useMemo(
    () => ({
      74: <AllMarketsFlagIcon />,
      66: <AmericaFlagIcon />,
      230: <CanadaFlagIcon />,
    }),
    []
  );

  const menu = useMemo(
    (): JSX.Element => (
      <Menu onClick={handleMarketDropdownMenuClick}>
        {markets?.options.map((option) => (
          <Menu.Item key={option?.id}>
            <div className="market-options">
              {marketIcons[option.id!]}
              {option?.name}
            </div>
          </Menu.Item>
        ))}
      </Menu>
    ),
    [handleMarketDropdownMenuClick, marketIcons, markets]
  );

  useEffect(() => {
    if (
      (hasPermission && location.pathname.includes(RoutePath.ProductLibrary)) ||
      location.pathname.includes(RoutePath.ProductList) ||
      location.pathname.includes(RoutePath.MasterLibrarianList) ||
      location.pathname.includes(RoutePath.UploadModels)
    ) {
      dispatch(searchProductsByName(''));
      dispatch(fetchMasterLibrarianListCount());
      dispatch(fetchCustomField('market'));
    }
  }, [debouncedSearchQuery, dispatch, hasPermission, location.pathname]);

  return (
    <Header className={siteHeaderClass}>
      <div className="header-wrapper">
        <div className="header-wrapper__section-left">
          {isTablet && (
            <img
              src={ReadySetIcon}
              alt="ReadySet icon"
              className="readyset-icon"
            />
          )}
          <Title level={4} data-cy="view-header-title">
            {getHeaderTitle()}
          </Title>
        </div>
        <div className="header-wrapper__section-right">
          {getHeaderTitle() === 'Product Library' ||
          getHeaderTitle() === 'Product List' ||
          getHeaderTitle() === 'Product List' ||
          getHeaderTitle() === 'Master Librarian List' ||
          getHeaderTitle() === 'Upload product model(s)' ||
          getHeaderTitle() === 'POG Checker' ||
          getHeaderTitle() === 'Product Library Meta Data' ||
          getHeaderTitle() === 'Replacement Data' ||
          getHeaderTitle() === 'Status' ? (
            <div className="header-section-right-btn-container">
              <Tooltip title="Master Librarian List">
                <Badge
                  count={masterLibrarianListCount}
                  overflowCount={99999}
                  offset={[10, 30]}
                  color="#EA088B"
                >
                  <Link to="/master-librarian-list">
                    <Button
                      icon={<MasterListIcon />}
                      className="header-section-right-button"
                    />
                  </Link>
                </Badge>
              </Tooltip>
              <Tooltip title="Product List">
                <Badge
                  count={productListCount}
                  overflowCount={99999}
                  offset={[10, 30]}
                  color="#8E24AA"
                >
                  <Link to="/product-list">
                    <Button
                      icon={<ProductListIcon />}
                      className="header-section-right-button"
                    />
                  </Link>
                </Badge>
              </Tooltip>
              <Dropdown
                placement="bottomRight"
                overlay={menu}
                trigger={['click']}
              >
                <Button
                  icon={React.cloneElement(marketIcons[selectedMarket], {
                    style: {
                      width: '34px',
                      height: '24px',
                    },
                  })}
                  className="market-button header-section-right-button"
                />
              </Dropdown>
            </div>
          ) : null}
          {isTablet && (
            <MenuOutlined
              className="toggle-menu-icon"
              onClick={onToggleSidebar}
            />
          )}
        </div>
      </div>
    </Header>
  );
};

export default memo(withRouter(AppHeader), propsAreEqual);
